import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm: React.FC = () => {
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: React.FormEvent) => {
        e.preventDefault();

        if (form.current) {
            emailjs
                .sendForm(
                    'service_t381mft',   // Your Email.js service ID
                    'template_32pthsb',  // Your Email.js template ID
                    form.current,
                    {
                        publicKey: 'uwOUSfj-M4yaHtLa0'
                    }// The actual form reference
                      // Your public key
                )
                .then((response) => {
                    console.log('Email sent', response.status, response.text);
                    alert('Message sent successfully!');
                })
                .catch((error) => {
                    console.error('Failed to send email:', error);
                    alert('Failed to send message. Please try again.');
                });
        }
    };

    return (
        <div id="contact-form">
        <form ref={form} onSubmit={sendEmail} className="bg-light p-3 rounded w-100 w-md-50 my-3" id="contact-form">
            <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" placeholder="Enter your name" required />
                <Form.Control.Feedback type="invalid">Please provide your name.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" name="email" placeholder="Enter email" required />
                <Form.Control.Feedback type="invalid">Please provide your email.</Form.Control.Feedback>
                <Form.Text className="text-muted">
                    I do not share your email with anyone else.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="phone">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="text" name="phone" placeholder="Enter phone number" required />
                <Form.Control.Feedback type="invalid">Please provide your phone number.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Preferred Contact Method</Form.Label>
                <Form.Check type="radio" name="contact_method" id="contactEmail" value="Email" label="Email" required />
                <Form.Check type="radio" name="contact_method" id="contactPhone" value="Phone" label="Phone" />
                <Form.Control.Feedback type="invalid">Please select your preferred contact method.</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="message">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" placeholder="Enter your message" required />
                <Form.Control.Feedback type="invalid">Please enter a message.</Form.Control.Feedback>
            </Form.Group>

            <div className="justify-content-center w-100 d-flex">
                <Button type="submit">Message Me</Button>
            </div>
        </form>
    </div>
    );
};

export default ContactForm
