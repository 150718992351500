import React, {Fragment} from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import {Image, Col, Row, Table} from "react-bootstrap";
import Footer from "../components/Footer";

const MyPractise: React.FC = () => {
    return (
        <Fragment>
            <Container className="bg-light my-3 p-3 rounded">
                <Row>
                    <Col>
                        <h1>My Practice</h1>
                        <p>
                            I currently offer counselling sessions in person in Edinburgh and East Lothian and online
                            when
                            required.

                        </p>
                        <p>
                            In terms of accessibility my counselling rooms in Edinburgh and East Lothian have a single
                            step
                            up from street level. Please arrive as close as possible to the start time of your session
                            as
                            there is no waiting area.
                        </p>
                        <h3>Location</h3>
                        <ul>
                            <li>Monday: East Lothian and online</li>
                            <li>Tuesday: PF Edinburgh and Edinburgh West End</li>
                            <li>Wednesday: Edinburgh Went End and online</li>
                        </ul>

                    </Col>
                    <Col className="justify-content-center my-3 d-flex"> <Image style={{maxHeight: '300px'}}
                                                                                src="images/rainbow.jpg"></Image>
                    </Col>
                </Row>
                <Row>

                    <h3>Pricing</h3>

                    <p>I suggest an initial 50 minute consultation for which I charge £30. Initial consultations take
                        place either online or in a traditional counselling room setting and allow us a sense of whether
                        - or how - we might work together. I'll ask you some standard questions, we'll talk generally
                        about what brings you to counselling and I'll go through my confidentiality policy. You'll also
                        be able to ask me questions if you'd like to. You may wish to use the contact form on this
                        website to initiate this process or telephone me to discuss your needs though please note that
                        the telephone number supplied on this website is only periodically checked for messages. </p>
                    <p>If we agree to work together, I charge £55 for 50 minutes face-to-face, online or outdoors,
                        weekly. We can discuss together how long we might initially contract to see each other for and
                        periodically review our arrangement. Concessions may be available for students or people on low
                        incomes. Payment by BACS is preferred where possible.</p>

                    <Table striped bordered size="sm">
                        <thead>
                        <tr>
                            <th>Option</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Initial Consultation</td>
                            <td>£30</td>
                        </tr>
                        <tr>
                            <td>Indoor (Weekly)</td>
                            <td>£55</td>
                        </tr>
                        <tr>
                            <td>Outdoor (Weekly)</td>
                            <td>£55</td>
                        </tr>
                        </tbody>
                    </Table>

                    <h3>Cancellation</h3>
                    <p>If a session is cancelled with less than a week's notice the cost of that session will be
                        charged. </p>
                </Row>
            </Container>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>

    )
}


export default MyPractise;

