import React, {Fragment} from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import Footer from '../components/Footer';

const Privacy: React.FC = () => {
    return (
        <Fragment>
            <Container className="bg-light my-3 p-3 rounded">
                <h1>Data Protection Policy</h1>
                <p>
                    Confidentiality and trust are at the heart of all good counselling. Please take time to read and
                    understand the following information about my privacy policy and about how and what information
                    I will keep. If you have any questions please let me know.

                </p>
                <p>
                    Any personal data that you give me ('Adria Stubbs', ’Adria Stubbs Counselling’ or 'the data
                    controller') via any means (verbal, written, in electronic form, or via my website) will be held
                    and processed in accordance with the data protection principles set out in the Data Protection
                    Act 1998 and the General Data Protection Regulation (GDPR) in order to provide the counselling
                    you require and in the legitimate interests of my business.
                </p>

                <p>
                    When you request counselling with me or otherwise provide your personal details to me you are
                    consenting to my use of your data under this policy. If you do not agree with any part of this
                    policy I may not be able to provide counselling. I will ask you to confirm that you have read
                    and understood this policy when regular counselling commences.

                </p>
                <p>
                    There are two kinds of personal information discussed in this policy: identifiable and
                    non-identifiable information. Identifiable information includes your name, address, date of
                    birth and contact details. Non-identifiable information includes any information which does not
                    contain these details.
                </p>
                <p>
                    This policy only applies to data collected by me, and via my own forms and website. Third party
                    agents and websites which are linked to mine are not covered by this policy.
                </p>
                <h2>Your Rights Under GDPR</h2>
                <ul>
                    <li>
                        Access If you would like to see the information about you that I keep or correct any
                        inaccuracies in your information please let me know.
                    </li>
                    <li>
                        Erasure If you would like to withdraw your consent for me to hold and process your data
                        please let me know. It is important that you are aware that if you do not give me consent to
                        do this then counselling will have to cease.
                    </li>
                    <li>Complaints You have a right to complain to the ICO ( ico.org.uk ) if you believe I am
                        mishandling your data.
                    </li>
                </ul>
                <h2>What Information Do I Collect?</h2>
                <ul>
                    <li>Counselling requests Requesting counselling can be done online through my website contact
                        form, by telephone or by email. I will ask for your name, telephone number and email
                        address. I may also request information regarding your availability, why you are looking for
                        counselling and other details which I believe to be relevant to our work together.
                    </li>
                    <li>
                        Initial assessment appointments At an initial appointment I will ask for you to confirm your
                        contact details, date of birth and the name and address of your GP. I will ask about your
                        background, which may include your personal, social, medical or financial situation, your
                        family history and previous experience of counselling, if any. I will ask about the issues
                        which are affecting you now and ask you to sign a counselling contract as well as a copy of
                        this privacy policy.
                    </li>
                    <li>
                        Weekly counselling appointments I keep brief notes on all sessions which include dates of
                        attendance, key themes and other important information discussed in our session.
                    </li>
                    <li>
                        Financial management I keep anonymised accounting records.
                    </li>
                    <li>
                        Email My email is powered by Gmail. The collection and use of data by Google is subject to
                        their own privacy policies. My account is password protected.
                    </li>
                    <li>
                        Website - my website is powered by one.com. The collection and use of any data that passes
                        through it is subject to their privacy and data protection policies (see one.com under
                        Product and Privacy Policies).
                    </li>
                </ul>

                <h2>What Do I Use Your Information For?</h2>
                <p>I use your identifiable information in the following ways:</p>
                <ul>
                    <li>
                        To respond to your enquiry about counselling and to provide you with information about my
                        service.
                    </li>
                    <li>To provide you with the professional counselling that you have requested.</li>
                    <li>To administer my service, including the arrangement of appointments and to request and receive
                        payment.
                    </li>
                    <li>To notify you about changes to your appointments or other changes to my services.</li>
                    <li>To gain feedback about your counselling experience with me and to improve my service.</li>
                </ul>


                <p>I use non-identifiable information about you in the following ways:</p>


                <ul>
                    <li>To provide and improve my service.</li>
                    <li>To ensure I am working to best practice, for example through on-going supervision.</li>
                    <li>To aid my own continuing professional learning, research and development.</li>
                </ul>
                <p>Should I consider using your identifiable or non-identifiable information for any other purpose I
                    will ask for your explicit permission before doing so. Please ask if you would like
                    clarification on any of these points.</p>
                <p>I may be required to pass identifiable and non-identifiable client information on to BACP or The
                    ICO in the event of a complaint or to my insurance company in the event of legal action.</p>
                <h2>What Information Do I Share?</h2>
                <p>I will not share identifiable information about you with other organisations or people except in
                    the following situations:</p>


                <ul>
                    <li>Consent I may share your information with others if you have asked me to and where you have
                        explicitly agreed for me to do this.
                    </li>
                    <li>Serious harm I may share your information with your GP or relevant authorities if I have reason
                        to believe that this may prevent serious harm being caused to you or another person. Wherever
                        possible I would discuss this with you first.
                    </li>
                    <li>Compliance with law I may share your information where I am required to by law.</li>
                    <li>In the event of my serious illness or death I have nominated a Clinical Executor, bound by the
                        confidentiality agreement between me and you, to get in contact with you in these circumstances.
                    </li>
                </ul>
                <h2>How do I keep your information safe?</h2>
                <p>All information you give me is stored as securely as possible. All paper forms and records are
                    kept in a locked filing cabinet. All electronic records are stored on my devices, access to
                    which are password protected, and storage maintained by reputable service providers using secure
                    internet ‘cloud’ technology.</p>


                <p>To further protect the confidentiality of our sessions I keep session notes and other
                    non-identifiable information separate from identifiable information wherever possible.</p>


                <p>Unfortunately, the transmission of information via the internet is never completely secure.
                    Although I will do my best to protect your information I cannot guarantee the security of your
                    data transmitted to me via email or through forms completed on my website. Any transmission is
                    at your own risk.</p>


                <p>Your identifiable personal information is kept separately from session notes and other
                    descriptive material. Client notes and other documentation is destroyed 3 years after the end of
                    counselling.</p>
                <h2>Changes to this policy</h2>
                <p>
                    I may edit this policy from time to time. If I make any substantial changes I will notify you in
                    person
                    or by email.
                </p>

            </Container>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>
    )
}


export default Privacy;

