import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap'
import Segment from "./Segment";

interface CustomJumbotronProps {
    title1: string;
    title2?: string;

    text: string;
    text2?: string;

    image: string;
    imageLeft: boolean;

    altName: string;
}

const CustomJumbotron: React.FC<CustomJumbotronProps> = ({title1, title2, text, text2, image, imageLeft, altName}) => {
    return (
        <Container className="bg-light p-3 px-5 mb-3 Jumbotron mx-auto rounded-3">
            <Row className="align-items-center">

                {/* Image */}
                <Col xs={12} md={4} className={`text-center ${imageLeft ? 'order-md-1' : 'order-md-2'}`}>
                    <Image className="mx-auto p-2 " src={image} fluid alt={altName} style={{maxHeight: '300px'}}/>
                </Col>

                {/* Segments */}
                <Col md={8} className={imageLeft ? 'order-md-2' : 'order-md-1'}>
                    <Segment subtitle={title1} text={text}/>
                    {title2 && text2 && <Segment subtitle={title2} text={text2}/>}
                </Col>
            </Row>

        </Container>
    )
}

export default CustomJumbotron;