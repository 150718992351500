import React from 'react';
import NewLine from "./NewLine";

const Segment : React.FC<{subtitle: string, text:string}> = ({subtitle, text}) => {
    return (
        <>
        <h2>{subtitle}</h2>
        <NewLine text={text}></NewLine>
        </>
    )
}

export default Segment;