import React, {Fragment} from 'react';
import CustomJumbotron from "../components/CustomJumbotron";
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Footer from "../components/Footer";

const Home: React.FC = () => {
    return (
        <Fragment>
            <div>
                <div className="masthead">
                    <Container fluid className="text-center text-white align-content-center color-overlay"
                               style={{height: '75vh'}}>
                        <h1 className="fs-1">Adria Stubbs Counselling</h1>
                        <Button className="m-4 green text-black px-4"
                                onClick={() => {
                                    const contactForm = document.getElementById("contact-form");
                                    if (contactForm) {
                                        contactForm.scrollIntoView({behavior: 'smooth'});
                                    } else {
                                        console.error("No contact form found");
                                    }
                                }}
                        >Get
                            in Touch</Button>
                    </Container>
                </div>
                <CustomJumbotron
                    title1={"Welcome to Adria Stubbs Counselling"}
                    text={"Hello and welcome to my counselling website. If you've found this page the chances are you're looking for support and a safe place to talk. Here you'll find information about me and my practice - enough, I hope, to make it possible for you to decide if you think I might be able to help you.\n\n If you need more information please do get in touch. " +
                        "I trained in counselling at The University of Edinburgh where I came to appreciate many of the challenges faced in understanding and accepting ourselves. I came to recognise the value of talking to a therapist who listens empathically and who can offer insight without judgement. \n\n"
                    }
                    image={"images/adriastubbs.jpg"}
                    imageLeft={true}
                    altName={"Adria Stubbs Counsellor"}/>
                <CustomJumbotron
                    title1={"What I Offer"}
                    text={"I aim to provide a safe, welcoming and confidential space in which to explore my client’s uniquely individual concerns.  I use my own creativity to tune in to the needs of my clients, supporting them in telling their story in whatever way feels right for them.\n\n  I currently work both online and face-to-face with adults in East Lothian and Edinburgh, working in a traditional counselling room setting for face-to-face sessions and sometimes outdoors.  I usually offer 50 minute sessions on a weekly basis.\n"}
                    image={"images/forest1.jpg"}
                    imageLeft={false}
                    altName={"Forest in East Lothian"}/>
                <CustomJumbotron
                    title1={"Optional Outdoors Work"}
                    text={"I also offer optional sessions in East Lothian where we work in and with the natural environment as an alternative way of exploring needs and gaining new perspectives.\n"}
                    image={'images/waves.jpg'}
                    imageLeft={true}
                    altName={"North Berwick Beach"}/>
            </div>

            <footer id="contact-form">
                <Footer/>
            </footer>
        </Fragment>
    )
}

export default Home;

