import React, {Fragment} from 'react';
import CustomJumbotron from "../components/CustomJumbotron";
import '../styles/styles.css';
import Footer from "../components/Footer";

const AboutCounselling : React.FC = () => {
    return (
        <Fragment>
        <div className="pt-3">
            <CustomJumbotron
                title1={"How Does Counselling Work?"}
                text={"Counselling can be difficult to explain because it means different things to different people and as counsellors we have different ways of working.\n\n  At the heart of all good counselling, however, is the relationship between the counsellor and the client.  It is this relationship which usually determines the success or otherwise of the therapy.  Being heard and having a space to focus on personal needs often produces a sense of relief.\n\n Over time coming into contact, engaging with and perhaps trying to make sense of our emotions can help clients feel more understanding of themselves and is what we often call ‘processing’.  This can help to effect fear, anger, stress, denial, guilt and shame.  The way in which we take responsibility in relationship often changes as a result of this work."
                }
                image={"images/rock.jpg"}
                imageLeft={true}
            altName={"Rock on East Lothian Beach"}/>
            <CustomJumbotron
                title1={"What I Offer"}
                text={"I am committed to building a safe and trusted connection with my clients so that they can explore freely what it is that’s troubling them.  I aim to offer a non-judgemental and empathic space where I am genuine and where clients feel accepted. During our work together I sometimes use my capacity for creativity and play to help my clients find their own individual solutions to their difficulties."
                }
                image={"images/trees.jpg"}
                imageLeft={false}
            altName={"Peaceful Forest in East Lothian"}/>
            <CustomJumbotron
                title1={"Why Pay for Counselling?"}
                text={"Many people come to describe counselling as an important personal investment.  It can bring positive benefits to them, their relationships and life generally.  Clients often know when counselling is working because they feel a change in themselves.  Regular reviews can help to focus on what this means for each client.  Paying for counselling means you might not have to wait quite as long to see someone.\n"}
                image={"images/dandelions.jpg"}
                title2={"How Long Do You Need?"}
                text2={"You may need just a few sessions to get you through or you may feel the need to come to counselling for some time before things feel different.  I am open to and comfortable with both possibilities."}
                imageLeft={true}
            altName={"Dandelions in nature"}/>
            <CustomJumbotron
                title1={"Ending"}
                text={"If clients decide not to continue it is important to have an ending session to look at what has been worked on, talk about what it feels like to end and think about coping strategies going forward.\n"}
                image={'images/sky.jpg'}
                imageLeft={false}
                altName={"Scottish Sky"}
                title2={"Clients - Experts In Their Own Experience"}
                text2={"In line with good professional practice, I do not give advice, although advice is often sought.  If you are looking for advice it may be that the process of talking this through is of benefit.\n\n I believe that my clients are uniquely positioned to understand themselves and their own needs and that with the right support and understanding they can come to feel empowered and grow.  Along the way I have faith in my clients knowing what they are - and may not be - ready to talk about."}/>
            <CustomJumbotron
                title1={"Working Outdoors"}
                text={"Working with clients outdoors allows for the exploration of feeling through engagement with nature.  This very flexible form of therapy can involve a ‘walk and talk’ approach, following an agreed route along the coast or inland, or perhaps meeting and relating in one place.  This work may be informed and/or effected by the weather.  Clients need not be very fit.  Traditional psychotherapeutic boundaries apply to this way of working (eg, time, cost, agreed place and focus) and we have an agreement around confidentiality should we meet people along the way. First or early sessions begin in the counselling room."}
                image={'images/shore.jpg'}
                imageLeft={true}
            altName={"Yellowcraig Beach"}/>
        </div>
    <footer id="contact-form">
        <Footer/>
    </footer>
    </Fragment>

    )
}


export default AboutCounselling;

