import Container from 'react-bootstrap/Container';
import {Navbar, Nav} from 'react-bootstrap'
import '../styles/styles.css';

function NavBar() {
    return (
    <div>
        <Navbar expand="lg" className="green">
            <Container>
                       <Navbar.Brand href="/" className="fs-2">Adria Stubbs Counselling</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto gap-4 fs-5">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/about">About Me</Nav.Link>
                                <Nav.Link href="/counselling">About Counselling</Nav.Link>
                                <Nav.Link href="/mypractise">My Practice</Nav.Link>
                                <Nav.Link href="/privacy">Privacy</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
    );
}

export default NavBar;