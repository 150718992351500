import React, { Fragment } from 'react';
import '../styles/styles.css';
import Container from "react-bootstrap/Container";
import {Image, Col, Row} from "react-bootstrap";
import Footer from "../components/Footer";

const About: React.FC = () => {
    return (
        <Fragment>
            <Container className="bg-light my-3 p-3 rounded">
                <Row>
                    <Col>
                        <h2>Background</h2>
                        <p>Before working as a counsellor I worked in both the profit and not-for-profit sectors,
                            including
                            work in South Asia and Eastern Europe. I graduated as a mature student in the 1990s and
                            spent a
                            number of years running my own gardening business. The empathy I offer in counselling is
                            enhanced by my experience of working with people from all walks of life, both in the UK and
                            overseas.</p>
                        <h2>Training</h2>

                        <p>In Edinburgh I trained in a combination of person-centred and psychodynamic counselling.
                            Using
                            these two approaches allows me to work imaginatively and flexibly, adapting the approach to
                            meet
                            the needs of each individual client.</p>

                    </Col>
                    <Col className="justify-content-center d-flex">                        <Image style={{maxHeight: '330px'}}
                               src="images/beach1.jpg" alt={"North Berwick Beach"}></Image>
                    </Col>
                </Row>
                <Row>

                    <h2>Experience</h2>
                    <p>As a counsellor I have worked with people who have difficulties with depression,
                        anxiety/stress,
                        loss, trauma, relationships, illness, faith, identity, self-esteem, learning, sexuality,
                        racial
                        difference and statelessness, bullying, abuse and neglect.</p>
                    <p>I've supported people with feelings
                        of
                        hopelessness, powerlessness and confusion, people who self harm and/or have OCD. I remain
                        open
                        to working with a broad range of issues.</p>
                    <h2>CPD</h2>
                    <p>I take continuous professional development seriously and have attended workshops and seminars
                        on
                        subjects such as attachment, fatherhood, dreams, play, autism, racial difference, sexuality
                        and
                        human trafficking.</p>
                    <h2>Regulation</h2>
                    <p>I am a member of BACP (British Association for Counselling and Psychotherapy) and adhere to
                        BACP’s Ethical Framework (https://www.BACP.org.uk).</p>
                </Row>
            </Container>
        <footer id="contact-form">
            <Footer />
        </footer>
        </Fragment>
    )
}


export default About;

