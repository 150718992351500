import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap'
import ContactForm from "./ContactForm";

const Footer: React.FC = () => {
    return (
            <Container fluid className="green pt-3  justify-content-center">
                <Row className="text-center">
                    <h1>Contact Me</h1>
                </Row>

                <Row className="justify-content-center col-lg-6 col-md-8 col-sm-11 mx-auto">
                    <ContactForm/>
                </Row>

                <Row className="bg-white text-black align-content-center text-center align-items-center">
                    <p className="text-center">Adria Stubbs | 07392 155 932 | adriastubbscounselling@gmail.com</p>
                </Row>
            </Container>

    )
}


export default Footer;